@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --primary: #01bf62;
  --primary-dark: #008342;
  --primary-hover: #03a051;
  --secondary: #f35d74;
  --secondary-hover: #dd5469;
  --primary-300: #03a051;
  --accent-100: #ffadad;
  --accent-200: #ffd6a5;
  --text-100: #4b4f5d;
  --text-200: #6a738b;
  --bg-100: #ffffff;
  --bg-200: #f5f5f5;
  --bg-300: #cccccc;
  --bg-black: rgb(55, 55, 55);
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: inherit !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.bg-primary-300 {
  background: var(--primary-300);
}

.text-primary {
  color: var(--primary-dark);
}

body {
  background-color: var(--bg-black);
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  height: calc(100vh - 65px);
}

aside {
  border-radius: 0 0 10px 0;
  overflow: hidden;
  border: none;
}

aside > div {
  background-color: var(--bg-black) !important;
}

aside ul li a span {
  color: var(--bg-100);
}

aside ul li > a.active {
  background-color: var(--primary);
}

aside ul li > a.active span {
  color: var(--bg-100);
}

aside .active-submenu {
  background: var(--bg-black);
}

.mainbar {
  background: var(--bg-black);
  width: 100%;
  height: 65px;
  padding-top: 15px;
  color: var(--bg-100);
}

.app-container {
  border-radius: 10px 0 0 0;
  background: var(--bg-200);
  overflow: scroll;
  height: calc(100vh - 65px);
}

.white-container {
  position: fixed;
  height: 60vh;
  background: var(--bg-200);
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
}

[aria-disabled="true"] {
  background: white !important;
}

.focus\:ring-cyan-300:focus {
  --tw-ring-color: transparent !important;
}

.title-syncx {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  color: rgb(79, 79, 79);
}

.title-syncx.white {
  color: white;
}

.title-syncx::before {
  content: "";
  height: 20px;
  width: 5px;
  border-radius: 10px;
  background: var(--primary);
}
.title-syncx.white::before {
  content: "";
  height: 20px;
  width: 5px;
  border-radius: 10px;
  background: white;
}

.card-query {
  cursor: pointer;
  transition: color 0.3s, font-weight 0.3s;
  background: white;
}

.card-query.active {
  background: var(--primary);
  color: #fff;
  font-weight: 600;
}

@media screen and (max-width: 639px) {
  .app-container {
    border-radius: 10px 10px 0 0;
    overflow: auto;
    height: auto;
    padding-bottom: 80px;
  }
}

button.primary,
.bg-primary,
.bg-cyan-700 {
  background: var(--primary);
}

button.secondary,
.bg-secondary {
  background: var(--bg-black);
}

button.primary:hover {
  background: var(--primary-hover) !important;
}

button.secondary:hover {
  background: var(--secondary-hover) !important;
}
.color-primary,
.text-cyan-600 {
  color: var(--primary);
}

.color-secondary {
  color: var(--secondary);
}

.border-cyan-600 {
  border-color: var(--primary);
}

input:focus,
select:focus {
  border-color: var(--primary) !important;
}

.card-dashboard > div {
  justify-content: flex-start;
}

.card-next-appointments > div {
  padding: 10px !important;
}

.card-invoice > div,
.card-working-hours > div {
  padding: 16px !important;
}

@media screen and (max-width: 768px) {
  .video-tutorials {
    width: 100%;
    height: 200px;
  }
}

.title {
  display: flex;
  gap: 10px;
  align-items: center;
  line-height: 20px;
  border-bottom: solid 3px var(--primary);
  width: fit-content;
  padding-bottom: 5px;
}

.loader-syncx {
  width: 48px;
  height: 48px;
  border: 3px dotted rgb(98, 98, 98);
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader-syncx::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted var(--primary);
  border-style: solid solid dotted;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.loading-pdf-overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 12px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}


.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.card-query {
  cursor: pointer;
  transition: color 0.3s, font-weight 0.3s;
  background: white;
}

.card-query > div {
  padding: 10px;
  min-height: 60px;
  font-size: 0.875rem;
}

.card-query.active {
  background: var(--primary);
  color: #fff;
  font-weight: 600;
}